html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: rgb(158, 34, 148);
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: black;
}

h1 {
    color: white;
    font: normal normal normal 50px/1.4em raleway, sans-serif;
    font-weight: bold;
    letter-spacing: 0.3em;
    font-size: 60px;
}

h2 {
    color: white;
    font-family: basic, sans-serif;
    text-shadow: rgba(255, 255, 255, 0.6) 1px 1px 1px, rgba(0, 0, 0, 0.6) -1px -1px 1px;
    letter-spacing: 0.17em;
}

#burgerMenu {
    display: none;
}

#word2 {
    padding-left: 25%;
}

#word3 {
    padding-left: 50%;
}

#word4 {
    padding-left: 75%;
}

.MuiList-root.MuiMenu-list.MuiList-padding > ul {
    padding-left: 0;
}

.raleway {
    font-family: 'Raleway', sans-serif;
}

.josefin {
    font-family: 'Josefin Slab', serif;
    letter-spacing: -0.05em;
    font-size: 40px;
    margin: 10px;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.marginLeft {
    margin-left: 20px;
}

.marginRight {
    margin-right: 20px;
}

.header {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 60px;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    z-index: 10;
}

.headerText {
    font: normal normal normal 16px/1.4em;
    font-family: 'Raleway', sans-serif;
}
/*
.react-photo-gallery--gallery {
    width: 90vw;
    margin-left: -16vw;
}
*/

.menuLink {
    margin-left: 30px;
}

.is-current {
    color: rgb(158, 34, 148);
    font-weight: bold;
}

.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    height: 50px;
    right: 0;
    bottom: 0;
    background-color: white;
    color: white;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 10px;
}

.headerLogo {
    margin: 0px 5px 5px 5px;
    height: 30px;
    width: auto;
}

.footerLogo {
    margin: 5px 5px 0px 5px;
    height: 30px;
    width: auto;
}

.bgImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(./pictures/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.logotext {
    display: contents;
}

.logo {
    margin: 20px;
    width: 10vw;
    height: auto;
}

.columnContent {
    background-color: rgb(223, 223, 223);
    padding-bottom: 50px;
}

svg {
    margin-top: 50px;
    margin-bottom: 20px;
}

.svgLine {
    stroke: rgba(62, 73, 28, 1);
    stroke-width: 2;
}

.svgTriangle {
    fill: transparent;
    stroke: rgba(62, 73, 28, 1);
    stroke-width: 1;
}

.title {
    text-shadow: rgba(255, 255, 255, 0.6) 1px 1px 1px, rgba(0, 0, 0, 0.6) -1px -1px 1px;
    letter-spacing: 0.1em;
    font: normal normal normal 18px/1.4em didot-w01-italic, serif;
    font-weight: bold;
    font-size: 45px;
    font-style: italic;
    color: black;
    text-align: center;
}

.center {
    display: flex;
    justify-content: center;
}

.larger {
    font-size: larger;
}

.textAndImg {
    display: flex;
    justify-content: center;
    align-items: center;
}

p.center {
    text-align: center;
}

.text {
    line-height: 1.6em;
    text-align: justify;
    font: normal normal normal 20px/1.4em;
    font-family: 'Raleway', sans-serif;
    color: #363636;
}

.burgerLogos {
    display: flex;
    justify-content: center;
}

#burgerLogo {
    padding-right: 20px;
}

.react-photo-gallery--gallery > div > .img:active {
    margin: 2px;
    display: block;
    position: absolute;
    width: 81.6vw;
    height: auto;
    z-index: 100;
    left: 0 !important;
    top: 0 !important;
}


@media screen and (max-width: 1200px) {
    #title {
        text-align: center;
    }

    .logo {
        display: none;
    }

    .bgImg {
        padding: 0 30px;
    }
}

@media screen and (max-width: 800px) {
    #title {
        text-align: center;
    }

    #burgerMenu {
        display: block;
    }

    #fullMenu {
        display: none;
    }

    #word1,
    #word2,
    #word3,
    #word4 {
        padding-left: 0;
    }

    #words {
        text-align: center;
    }

    .logo {
        display: none;
    }

    .footer {
        display: none;
    }

    .MuiGrid-root.columnContent.MuiGrid-item.MuiGrid-grid-xs-7 {
        max-width: none;
        flex-basis: unset;
    }

    .MuiGrid-root.bgImg.MuiGrid-item.MuiGrid-grid-xs-10 {
        max-width: 100vw;
        flex-basis: unset;
    }

    .react-photo-gallery--gallery > div > .img {
        pointer-events: none;
    }
}

@media screen and (max-width: 640px) {
    .noPhone {
        display: none;
    }

    div.textPhone {
        max-width: 83%;
        flex-basis: 83%;
    }

    .react-photo-gallery--gallery > div > .img {
        pointer-events: none;
    }

    h1 {
        font-size: revert;
    }
}
